<nav class="neo-navbar">
    <div class="neo-nav--left">
      <a class="neo-navbar__brand" title="Avaya">
        <img src="../assets/avaya logo.svg" />
      </a>
      <a class="neo-navbar__brand" title="OneCloud CCAAS">
        <img src="../assets/onecloud-ccaas.svg" />
      </a>
    </div>
  </nav>
  <div class="row av-main">
    <div class="av-main-img col-sm-12 col-lg-5" >
      <img src="../assets/image.png" style="width: max-content; max-width: 100%;" class="neo-img neo-img--fluid" />
    </div>
    <div class="av-main-form col-sm-12 col-lg-7">
        <h2>Welcome to Avaya CCaaS </h2>
        <h4>Please select your location to continue</h4>
        <form class="neo-form form-inline" [formGroup]="myForm" (ngSubmit)="siteRedirect()">
          <div class="neo-form-control">
            <div class="neo-input-group">
                  <div class="neo-select av-location-select">
                    <select style="font-family:noto-sans,sans-serif;" class="form-control" id="locationOption"
                      formControlName="location" class="neo-icon-chevron-down">
                      <option value="https://na.cc.avayacloud.com/services/ApplicationCenter/">North America</option>
                      <option value="https://uk.cc.avayacloud.com/services/ApplicationCenter/">United Kingdom</option>
                      <option value="https://sa.cc.avayacloud.com/services/ApplicationCenter/">South America</option>
                      <option value="https://eu.cc.avayacloud.com/services/ApplicationCenter/">Europe</option>
                      <option value="https://eu.cc.avayacloud.com/services/ApplicationCenter/">UAE</option>
                      <option value="https://ap.cc.avayacloud.com/services/ApplicationCenter/">Singapore</option>
                      <option value="https://au.cc.avayacloud.com/services/ApplicationCenter/">Australia</option>
                    </select>
                  </div>
            </div>
          </div>
          <button dir="rtl" type="submit" class="neo-btn neo-btn-primary neo-btn-primary--default
          neo-icon-arrow-right go-button">Go
          </button>
        </form>
  
  
    </div>
  
  </div>
  
  <div class="widget-box">
    <div class="row">
      <div class="col">
        <a href="https://onecare.avaya.com"><div class="widget-container">
          <div className="neo-widget__content">
            <div className="neo-widget__header">
              <div class="neo-widget__header-left">
                <span style="color: #212529;" class="neo-icon-help"></span>
              </div>
              <div class="neo-widget__header-right">
  
              </div>
            </div>
            <div className="neo-widget__body neo-widget__body">
              <p className="neo-widget__message neo-widget__message">
                <a href="https://onecare.avaya.com" class="textlink">Help</a>
              </p>
            </div>
          </div>
        </div>
        </a>
      </div>
      <div class="col">
        <a href="https://www.avaya.com/en/partners/devconnect/"><div class="widget-container">
          <div className="neo-widget__content">
            <div className="neo-widget__header">
              <div class="neo-widget__header-left">
                <span style="color: #212529;" class="neo-icon-cart"></span>
              </div>
              <div class="neo-widget__header-right">
  
              </div>
            </div>
            <div className="neo-widget__body neo-widget__body">
              <p className="neo-widget__message neo-widget__message">
                <a class="textlink" href="https://www.avaya.com/en/partners/devconnect/">Marketplace</a>
              </p>
            </div>
          </div>
        </div>
        </a>
      </div>
      <div class="col">
        <a href="https://documentation.avaya.com/"><div class="widget-container">
          <div className="neo-widget__content">
            <div className="neo-widget__header">
              <div class="neo-widget__header-left">
                <span style="color: #212529;" class="neo-icon-posts"></span>
              </div>
              <div class="neo-widget__header-right">
  
              </div>
            </div>
            <div className="neo-widget__body neo-widget__body">
              <p className="neo-widget__message neo-widget__message">
                <a href="https://documentation.avaya.com/" class="textlink">Documentation</a>
              </p>
            </div>
          </div>
        
        </div>
      </a>
      </div>
  
      <div class="col">
        <a href="https://developers.avayacloud.com/"><div class="widget-container">
          <div className="neo-widget__content">
            <div className="neo-widget__header">
              <div class="neo-widget__header-left">
                <span  style="color: #212529;"class="neo-icon-code"></span>
              </div>
              <div class="neo-widget__header-right">
  
              </div>
            </div>
            <div className="neo-widget__body neo-widget__body">
              <p className="neo-widget__message neo-widget__message">
                <a href="https://developers.avayacloud.com/" class="textlink">Developer</a>
              </p>
            </div>
          </div>
        </div>
      </a>
      </div>
  
      <div class="col">
        <a href="https://www.avaya-learning.com"> <div class="widget-container">
          <div className="neo-widget__content">
            <div className="neo-widget__header">
              <div class="neo-widget__header-left">
                <span style="color: #212529;" class="neo-icon-billboard"></span>
              </div>
              <div class="neo-widget__header-right">
  
              </div>
            </div>
            <div className="neo-widget__body neo-widget__body">
              <p className="neo-widget__message neo-widget__message">
                <a href="https://www.avaya-learning.com" class="textlink">Learning</a>
              </p>
            </div>
          </div>
        </div>
      </a>
      </div>
  
  
    </div>
  </div>
  
  <div class="av-footer">
    <div class="footer-pattern-bg">
      <img src="https://www.avaya.com/static/avaya/assets/images/footer-pattern@1x.png">;
    </div>
    <div class="footer">
      <div class="row">
        <div class="inner">
          <div class="col">
            <h4>Headquarters Avaya Inc</h4>
            <p>2605
              Meridian Parkway</p>
  
            <p>Suite 200</p>
  
            <p>Durham, North Carolina</p>
  
            <p>27713, USA</p>
          </div>
          <div class="col">
  
            <h4>Products &amp; Solutions</h4>
            <p><a href="https://www.avaya.com/en/onecloud/" target="_self">Avaya
                OneCloud</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/products/ccaas/" target="_self">Contact
                Center</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/products/ucaas/" target="_self">Unified
                Communications</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/products/devices-and-phones/" target="_self">Devices and Phones</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/services/" target="_self">Services</a></p>
            <p><a href="https://www.avaya.com/en/products/small-business-solutions/" target="_self">Small &amp;
                Midsize</a></p>
          </div>
          <div class="col">
            <h4>Partners</h4>
  
            <p><a href="https://www.avaya.com/en/partners/channelpartners/" target="_self" class="" style="">Channel</a>
            </p>
  
  
  
            <p><a href="https://www.avaya.com/en/partners/salesagents/" target="_self" class="" style="">Sales Agent</a>
            </p>
  
  
  
            <p><a href="https://www.avaya.com/en/partners/openproductresellers/" target="_self" class="" style="">Open
                Product</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/partners/alliances/" target="_self" class="" style="">Alliances</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/partners/devconnect/" target="_self">DevConnect</a></p>
  
  
  
            <p><a href="https://sales.avaya.com/" target="_self" class="" style="">Login to Partner Portal</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/partner-locator/" target="_self" class="" style="">Find a Partner</a></p>
          </div>
          <div class="col">
            <h4>Company</h4>
  
  
  
  
  
            <p><a href="https://www.avaya.com/en/about-avaya/" target="_self" class="" style="">About Avaya</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/success-stories/" target="_self">Success
                Stories</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/about-avaya/newsroom/" target="_self" class="" style="">Newsroom</a></p>
  
  
  
            <p><a href="https://investors.avaya.com/home/default.aspx" target="_self" class="" style="">Investor
                Relations</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/about-avaya/careers/welcome/" target="_self" class=""
                style="">Careers</a></p>
  
  
  
            <p><a href="https://www.avaya.com/blogs/" target="_self" class="" style="">Blog</a></p>
  
  
  
            <p><a href="https://www.avaya.com/en/videos/" target="_self" class="" style="">Videos</a></p>
  
  
  
            <p><a href="https://www.iaug.org/" target="_self" class="" style="">User
                Group</a></p>
          </div>
        </div>
        <div class="social-share">
          <p class="stay-connnected">STAY CONNECTED</p>
          <div class="social-icons">
            <div class="icons">
  
  
  
              <div class="social-icon-wrap"><a target="_blank" rel="noreferer,
                  ,noopener" href="https://twitter.com/avaya"><i class="fa
                    fa-twitter"></i></a></div>
              <div class="social-icon-wrap"><a target="_blank" rel="noreferer, ,noopener"
                  href="https://www.facebook.com/avaya"><i class="fa fa-facebook"></i></a></div>
              <div class="social-icon-wrap"><a target="_blank" rel="noreferer,
                  ,noopener" href="https://www.youtube.com/avaya"><i class="fa
                    fa-youtube-play"></i></a></div>
              <div class="social-icon-wrap"><a target="_blank" rel="noreferer, ,noopener"
                  href="https://www.linkedin.com/company/avaya/"><i class="fa
                    fa-linkedin"></i></a></div>
              <div class="social-icon-wrap"><a target="_blank" rel="noreferer, ,noopener"
                  href="https://www.instagram.com/avayainc/"><i class="fa
                    fa-instagram"></i></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="av-sub-footer">
      <div class="inner">
        <div class="av-logo">
          <img alt="Avaya Logo" src="https://www.avaya.com/static/avaya/assets/svg/avaya-logo-red.svg">
        </div>
        <div class="policy">
          <p><a href="/en/sitemap/" target="_self">Sitemap</a></p>
  
          <p><a href="/en/termsofuse/" target="_self">Terms of Use</a></p>
  
          <p><a href="/en/privacy/commitment/" target="_self">Privacy</a></p>
          <p><a href="" target="_self">Cookies</a></p>
          <!-- 
  <div class="optanon-show-settings-popup-wrapper"><div class="optanon-show-settings-button optanon-toggle-display"><div class="optanon-show-settings-left"></div><div class="optanon-show-settings-middle"><p><a class="optanon-show-settings" data-ignore-geolocation="true" href="" target="_self">Cookies</a></p></div><div class="optanon-show-settings-right"></div></div></div> -->
          <p><a href="/en/trademarks/" target="_self">Trademarks</a></p>
  
          <p><a href="/en/accessibility/" target="_self">Accessibility</a></p>
        </div>
        <div class="end-tag">© 2021 Avaya Inc. </div>
      </div>
    </div>
  </div>
  
