import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  title = 'landing-page';
  myForm: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }
  private createForm() {
    this.myForm = this.formBuilder.group({
      location: 'https://na.cc.avayacloud.com/services/ApplicationCenter/',
    });
  }

  selectedIndex;
  siteRedirect(){
    window.location.href=this.myForm.value.location;
   console.log(this.myForm.value.location)
  }

}
