<div class="row av-main">
    <div class="av-main-img col-sm-12 col-lg-6" >
      <img src="../assets/trust.png" style="width: max-content; max-width: 100%;" class="neo-img neo-img--fluid" />
    </div>

    <div class="av-main-form col-sm-12 col-lg-6" >
        <div class="right-content" style="margin-top:15%">
        <a href="#"><img src="../assets/avaya.png" style="width: max-content; max-width: 100%;" class="neo-img neo-img--fluid" /></a>&nbsp;  &nbsp; <a href="#"><img src="../assets/onecloud-ccaas.svg" style="width: max-content; max-width: 100%;" class="neo-img neo-img--fluid" /></a>
        </div>
        <div class="right-content" style="margin-top:15%;font-weight: 400;size: 44;">
        <h1>Trust Center <br> Coming Soon</h1>
        </div>
        </div>

</div>